import { render, staticRenderFns } from "./reporte_workflow.vue?vue&type=template&id=2e1f6504&"
import script from "./reporte_workflow.vue?vue&type=script&lang=js&"
export * from "./reporte_workflow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAutocomplete,VBtn,VCol,VContainer,VDataTable,VDialog,VIcon,VRow,VSheet})
